import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withFirebase } from '../../../utils/Firebase';

import TermsOfUseModal from '../../molecules/TermsOfUseModal/terms-of-use-modal';
import SignUpForm from '../../molecules/SignUpForm/sign-up-form';
import SignUpLink from '../../atoms/SignUpLink/sign-up-link';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';

import { INVENTORIES } from '../../../constants/routes';

import Logo from '../../../assets/adsight.png';

// Styles
import './sign-up.scss';

const INITIAL_SIGNUP_FORM_VALUE = {
  email: '',
  password: '',
  passwordConfirmation: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  companyName: '',
};

// eslint-disable-next-line
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const SIGNUP_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .min(2, 'Too Short')
    .max(30, 'Too Long'),
  lastName: Yup.string()
    .required('Required')
    .min(2, 'Too Short')
    .max(30, 'Too Long'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Your phone number is invalid')
    .required('Required'),
  companyName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required').min(6, 'Too Short'),
  passwordConfirmation: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SignUp = ({ firebase }) => {
  const incomRedirectPath =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : undefined;

  const redirectURL = incomRedirectPath && incomRedirectPath.get('redirectURL');

  const formik = useFormik({
    initialValues: INITIAL_SIGNUP_FORM_VALUE,
    validationSchema: SIGNUP_SCHEMA,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await firebase.doCreateUserWithEmailAndPassword(values);
        const redirectPath = findPath();
        setLoading(false);
        navigate(redirectPath);
      } catch (signupError) {
        setLoading(false);
        const errorMsg = signupError.message.split(':')[1].trim();
        setSignupResponse({
          type: 'error',
          title: 'Sign up Error',
          message: errorMsg,
        });
      }
    },
  });

  const [loading, setLoading] = useState(false);

  const [termsOfUseModal, setTermsOfUseModal] = useState(false);
  const [signupResponse, setSignupResponse] = useState(undefined);

  const openModal = () => setTermsOfUseModal(true);
  const closeModal = () => setTermsOfUseModal(false);

  const findPath = () => {
    if (redirectURL) return redirectURL;
    else {
      const whiteLabeledViewSupplier = localStorage.getItem('supplier');
      if (whiteLabeledViewSupplier) {
        return `${INVENTORIES}/${whiteLabeledViewSupplier}`;
      } else {
        return INVENTORIES;
      }
    }
  };

  const deleteSignupResponseMessage = () => setSignupResponse(undefined);

  return (
    <Fragment>
      {signupResponse && (
        <ResponseHandler
          response={signupResponse}
          deleteMessage={deleteSignupResponseMessage}
        />
      )}
      <TermsOfUseModal
        modalIsActived={termsOfUseModal}
        closeModal={closeModal}
      />
      <div className="gradient-bar" />
      <div className="sign-up">
        <div className="sign-up__content">
          <div className="sign-up__content__header">
            <img
              className="login__content__header__logo"
              src={Logo}
              alt="logo"
            />
          </div>
          <SignUpForm
            values={formik.values}
            dirty={formik.dirty}
            touched={formik.touched}
            errors={formik.errors}
            isValid={formik.isValid}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            loading={loading}
          />
          <div className="sign-up__content__sign-in-link">
            <SignUpLink redirectURL={redirectURL} />
          </div>
          <div className="sign-up__content__terms-of-use">
            <p>
              By clicking Sign Up, you agree to our
              <button
                className="sign-up__content__terms-of-use__button"
                onClick={openModal}
              >
                Terms of Service.
              </button>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withFirebase(SignUp);
