import React, { useEffect } from 'react';
import classNames from 'classnames';

import Button from '../../atoms/Button/button';

import './terms-of-use-modal.scss';

export default ({ modalIsActived, closeModal }) => {
  const handleCloseModal = (event) => {
    if (event.keyCode === 27) return closeModal();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keyup', handleCloseModal, false);
    }

    return () => {
      window.removeEventListener('keyup', handleCloseModal, false);
    };
  });

  return (
    <div
      className={classNames('modal', {
        'is-active': modalIsActived,
      })}
    >
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-content terms-of-use-modal">
        <div className="terms-of-use-modal__close-button">
          <i className="fas fa-2x fa-times" onClick={closeModal} />
        </div>
        <div className="terms-of-use-modal__title">
          <p className="is-size-3 has-text-weight-medium">Terms of Service</p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Welcome to Adsight, a website located at www.Adsight.ca the "Site")
            and operated by Adsight, Inc ("Adsight", "us", "our", and "we").
            Adsight provides the Site and services provided through the Site
            ("Services") including a marketplace to buy and sell outdoor
            advertising.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph has-text-weight-medium">
            These Terms of Use ("Agreement") set forth the legally binding terms
            for your use of the Services. By accessing or using the Services,
            you are accepting this Agreement and you represent and warrant that
            you have the right, authority, and capacity to enter into this
            Agreement. If you do not agree with all of the provisions of this
            Agreement, do not access and/or use the Services. You may not access
            or use the Services or accept the Agreement if you are not at least
            18 years old.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            About Adsight
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            The Adsight Advertising Marketplace is created as a self-serve
            advertising platform that connects buyers and sellers of outdoor
            advertising space. It enables businesses to discover outdoor space
            on which to advertise their products or services. It assists owners
            in listing their inventory.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Adsight is a self-service platform. Adsight does not negotiate
            advertising terms on your behalf. You must negotiate yourself. You
            have the right to accept or reject any advertising opportunity
            presented to you.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Types of Users
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            "The User," "you" and "your" refer to the person, company, or
            organization that has visited or is using the Site and/or the
            Service. A User may be a Buyer, a Seller, or both, or neither.
            "Seller" refer to individuals or businesses who use our Site to sell
            outdoor advertising space ("Ad Space"). "Buyer" refer to individuals
            or businesses who use our Site to purchase Ad Space.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Account
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Account Creation. In order to use certain features of the Services,
            you must register for an account with us ("your Account") and
            provide certain information about yourself as prompted by the
            registration form. You represent and warrant that: (a) all required
            registration information you submit is truthful and accurate; (b)
            you will maintain the accuracy of such information. You may delete
            your Account at any time, for any reason, by following the
            instructions on the Site. We may suspend or terminate your Account
            in accordance with the Terms and Termination. Account
            Responsibilities. You are responsible for maintaining the
            confidentiality of your Account login information and are fully
            responsible for all activities that occur under your Account. You
            agree to immediately notify us of any unauthorized use, or suspected
            unauthorized use, of your Account or any other breach of security.
            We cannot and will not be liable for any loss or damage arising from
            your failure to comply with the above requirements.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Our Role as a Marketplace Platform
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Adsight is Not a Party to Contracts. Sellers are not the employees
            or agents of Adsight. We are not involved in agreements between
            Users. Users represent, understand and expressly agree that we do
            not have control over the quality, timing, legality or any other
            aspect whatsoever of the services delivered by the Sellers, nor of
            any of the actions whatsoever of the Buyers. At no point may we be
            held liable for the actions or omissions of any Seller performing
            services for you.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Adsight Does Not Guarantee Results. Providing a service where
            potential Buyers and Sellers can meet does not imply an endorsement
            of any subscribing Seller. We do not sanction statements a Seller
            may post on the system. While we use commercially reasonable efforts
            to verify the identity and background of any Sellers we do not make
            any warranty, guarantee, or representation as to the ability,
            competence, quality, or qualifications of any Seller.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Reviews. From time to time, Buyers may submit reviews of Sellers and
            vice versa; these reviews do not constitute a guarantee, warranty,
            or prediction regarding the outcome of any future matter. We will
            have no responsibility or liability of any kind for any User Content
            (defined below) you encounter on or through the Site, and any use or
            reliance on User Content is solely at your own risk.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Listings. Sellers and Buyers are required to make truthful
            statements and material disclosures on any listings or applications
            to listings that would enable the other user to make an informed
            decision on whether to exchange services with you. Adsight does not
            control and takes no responsibility for the information that any
            user includes in his or her account, listing, or application.
            Adsight does not set prices.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            For Sellers
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Seller Responsibilities. The Seller is responsible for the
            following:
          </p>
          <ul>
            <li>Displaying physical graphics provided by the Buyer</li>
            <li>
              Providing photographic proof of the display of the ad throughout
              the duration of the ad.
            </li>
            <li>Vetting the content of the ad</li>
            <li>Posting accurate Listings</li>
            <li>
              Ensuring all advertisements conform to the laws of the geographic
              location of the Ad Space
            </li>
            <li>Respond to all Buyer Requests within 24 hours.</li>
          </ul>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            By reaching an agreement with the Buyer, you are bound to the terms
            of the advertising campaign agreement. If you fail to meet the
            requirements of the advertising campaign as stated in the
            advertising campaign agreement accepted by you, then you are in
            breach of the advertising campaign contract. If you breach the
            advertising campaign contract, without cause, you agree to reimburse
            Adsight for any expenses, including legal fees and loss of revenue
            to Adsight that results from your breach of the contract.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Fees. We may charge a transaction fee for each agreement facilitated
            on our Platform in exchange for the services we provide to our
            Users. We may change the transaction fee in our sole discretion.
            Sellers will be notified of any changes in the transaction fee.
            Sellers are responsible for paying any taxes incurred by Seller for
            payments received through the Site.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            For Buyers
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Buyer's Responsibilities. Buyers are responsible for providing
            Seller will all graphic information that will be displayed on the Ad
            Space. All graphic information must conform to the specifications
            provided by Seller.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Payment Process. You are responsible for all fees, including taxes,
            associated with your use of the Service. All fees for advertising
            will be paid upfront. Payment will be processed once an agreement is
            reached between the Buyer and Seller. All information that you
            provide in connection with a purchase or transaction must be
            accurate, complete, and current. You agree to pay all charges
            incurred by users of your credit card, debit card, or other payment
            method used about a purchase or transaction at the prices in effect
            when such charges are incurred.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Payment Authorization. By agreeing to these terms, you are giving us
            permission to charge your on-file credit card, PayPal account, or
            other approved methods of payment for fees that you authorize us to
            satisfy.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Chargebacks. If you have a dispute concerning any payment
            transaction, please contact our billing department. If, at any time,
            you contact your bank or credit card company to reject the charge of
            any payable Fees ("Chargeback"), this act will be considered a
            breach of your payment obligations, and we reserve the right to
            automatically terminate your use of the Services. We reserve the
            right to dispute any Chargeback and take all reasonable action to
            authorize the transaction. In the event of a Chargeback, your User
            Account may be terminated and any files, data, or content contained
            in your Account may be subject to cancellation. We will not be
            liable for any loss of files, data or content because of a
            Chargeback. Without limiting other available remedies, Buyer must
            pay Adsight upon demand for amounts owed under this Agreement.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Disintermediation
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            We expend tremendous resources to provide our platform and services,
            to connect Buyers and Sellers. You are prohibited from making
            side-agreements or engaging in activities that can be deemed
            competitive to Adsight with any user outside of our platform. Once
            introduced to a Seller and/or Buyer on our platform, you are
            prohibited from entering any business transactions or agreements
            outside our Platform. Sellers agree not to receive direct payments
            from any Buyers for the services you provide and you will only offer
            your services to our Buyers through our platform. Buyers agree not
            to send direct payments to Sellers.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Disputes Between Buyers and Sellers
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Adsight does not intervene in disputes between a Buyer and Seller.
            Adsight is not responsible for issuing refunds for any services that
            Buyer purchased from Seller. In order to initiate a refund request,
            you must contact the Seller directly. Buyers and Sellers agree to
            indemnify and hold Adsight harmless from any and all damages or fees
            incurred as a result of your interactions on our Site.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Rights and Licenses
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            License to Use Site. We grant you a non-transferable, non-exclusive,
            right to access and use the Services for your personal use.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Certain Restrictions. The rights granted to you in this Agreement
            are subject to the following restrictions: (a) you will not license,
            sell, rent, lease, transfer, assign, distribute, host, or otherwise
            commercially exploit the Services; (b) you will not modify, make
            derivative works of, disassemble, reverse compile or reverse
            engineer any part of the Services; (c) you will not access the
            Services in order to build a similar or competitive service; and (d)
            except as expressly stated in these terms, no part of the Services
            may be copied, reproduced, distributed, republished, downloaded,
            displayed, posted or transmitted in any form or by any means. Any
            future release, update, or other addition to functionality of the
            Services will be subject to the terms of this Agreement. All
            copyright and other proprietary notices on any Services content must
            be retained on all copies.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Modification. We reserve the right, at any time, to modify, suspend,
            or discontinue the Services with or without notice. You agree that
            we will not be liable to you or to any third party for any
            modification, suspension, or discontinuance of the Services, except
            and if otherwise expressly set forth in these Terms.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            No Support or Maintenance. You acknowledge and agree that we will
            have no obligation to provide you with any support or maintenance in
            connection with the Services.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Ownership of the Services. Excluding your User Content (defined
            below), you acknowledge that all the intellectual property rights,
            including copyrights, patents, trademarks, and trade secrets, in the
            Services, including the Site, are owned by us or our licensors. The
            provision of the Services does not transfer to you or any third
            party any rights, title or interest in or to the intellectual
            property rights. We reserve all rights not granted in this
            Agreement.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            User Content
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            User Content. "User Content" means any and all information and
            content that a user submits to or posts on: (a) the Services and (b)
            on social networking sites where we have a page or presence. You
            will own your User Content, with the understanding that you agree
            that we may use and reproduce the User Content you make available on
            our social networking sites and on the Services. You are solely
            responsible for the User Content that you post, upload, link to or
            otherwise make available via the Service. We reserve the right to
            remove any User Content from the Service at our discretion.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            The following rules pertain to User Content. By transmitting and
            submitting any User Content while using the Service, you agree as
            follows:
          </p>
          <ul>
            <li>
              You are solely responsible for your account and the activity that
              occurs while signed in to or while using your account.
            </li>
            <li>
              You will not submit content that is copyrighted or subject to
              third party proprietary rights, including privacy, publicity,
              trade secret, etc., unless you are the owner of such rights or
              have the appropriate permission from their rightful owner to
              specifically submit such content.
            </li>
            <li>You will abide by our Acceptable Use Policy below.</li>
            <li>
              You affirm we have the right to determine whether any of your User
              Content submissions are appropriate and comply with these Terms of
              Use, remove any and/or all of your submissions, and terminate your
              account with or without prior notice.
            </li>
          </ul>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            You understand and agree that any liability, loss or damage that
            occurs as a result of the use of any User Content that you make
            available or access through your use of the Service is solely your
            responsibility. We are not responsible for any public display or
            misuse of your User Content. We do not, and cannot, pre-screen or
            monitor all User Content. However, at our discretion, we, or
            technology we employ, may monitor and/or record your interactions
            with the Service.
          </p>
          <p>
            License. You grant, and you represent and warrant that you have the
            right to grant, to us an irrevocable, nonexclusive, royalty-free and
            fully paid, worldwide license to reproduce, distribute, publicly
            display and perform, prepare derivative works of, incorporate into
            other works, and otherwise use your User Content, and to grant
            sublicenses of the foregoing, solely for the purposes of including
            your User Content in the Site and Services. You agree to irrevocably
            waive (and cause to be waived) any claims and assertions of moral
            rights or attribution with respect to your User Content.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Acceptable Use Policy. Your permission to use the Services is
            conditioned upon the following Use Restrictions and Conduct
            Restrictions: You agree that you will not under any circumstances:
          </p>
          <ul>
            <li>
              post any information that is abusive, threatening, obscene,
              defamatory, libelous, or racially, sexually, religiously, or
              otherwise objectionable and offensive; use the service for any
              unlawful purpose or for the promotion of illegal activities.
            </li>
            <li>
              attempt to, or harass, abuse or harm another person or group.
            </li>
            <li>use another user's account without permission.</li>
            <li>
              provide false or inaccurate information when registering an
              account.
            </li>
            <li>
              interfere or attempt to interfere with the proper functioning of
              the Service.
            </li>
            <li>
              make any automated use of the system, or take any action that we
              deem to impose or to potentially impose an unreasonable or
              disproportionately large load on our servers or network
              infrastructure.
            </li>
            <li>
              use the Site or any of its contents to advertise or solicit, for
              any commercial purpose or to compete, directly or indirectly, with
              our Service.
            </li>
            <li>
              bypass any robot exclusion headers or other measures we take to
              restrict access to the Service or use any software, technology, or
              device to scrape, spider, or crawl the Service or harvest or
              manipulate data.
            </li>
            <li>
              publish or link to malicious content intended to damage or disrupt
              another user's browser or computer.
            </li>
          </ul>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Feedback. If you provide us any feedback or suggestions regarding
            the Services ("Feedback"), you assign to us all rights in the
            Feedback and agree that we will have the right to use the Feedback
            and related information in any manner we deem appropriate. We will
            treat any Feedback you provide to us as non-confidential and
            nonproprietary. You agree that you will not submit to us any
            information or ideas that you consider to be confidential or
            proprietary.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Indemnity. You agree to indemnify and hold us (and our officers,
            employees, and agents) harmless, including costs and attorney's'
            fees, from any claim or demand made by any third party due to or
            arising out of (a) your use of the Services, (b) your User Content,
            (c) your violation of this Agreement; or (d) your violation of
            applicable laws or regulations. We reserve the right, at your
            expense, to assume the exclusive defense and control of any matter
            for which you are required to indemnify us and you agree to
            cooperate with our defense of these claims. You agree not to settle
            any matter without our prior written consent. We will use reasonable
            efforts to notify you of any such claim, action or proceeding upon
            becoming aware of it.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Links to Other Sites and/or Materials Third Party Sites, Ads and Ad
            Networks. As part of the Service, we may provide you with convenient
            links to third party website(s) ("Third Party Sites") as well as
            content or items belonging to or originating from third parties (the
            "Third Party Applications, Software or Content"). Users may also
            include links to their website or other Third Party Sites on their
            listings. These links are provided as a courtesy to Service
            subscribers. We have no control over Third Party Sites and Third
            Party Applications, Software or Content or the promotions,
            materials, information, goods or services available on these Third
            Party Sites or Third Party Applications, Software or Content. If you
            decide to leave the Site and access the Third Party Sites or to use
            or install any Third Party Applications, Software or Content, you do
            so at your own risk and you should be aware that our terms and
            policies no longer govern. You should review the applicable terms
            and policies, including privacy and data gathering practices, of any
            site to which you navigate from the Site or relating to any
            applications you use or install from the site.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Links to Our Site. You are permitted to link to our Site for
            noncommercial purposes, provided that you do so in a way that is
            fair and legal and does not damage our reputation. You may not link
            to our Site in such a way as to suggest any form of association,
            approval, or endorsement on our part without our express written
            consent. You may not deep-link to any page of this site for any
            purpose whatsoever unless the link is expressly authorized in
            writing by us. We reserve the right to withdraw permission for any
            link.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Release. You release and forever discharge us (and our officers,
            employees, agents, successors, and assigns) from, and waive and
            relinquish, each and every past, present and future dispute, claim,
            controversy, demand, right, obligation, liability, action and cause
            of action of every kind and nature (including personal injury,
            death, and property damage), that has arisen or arises directly or
            indirectly out of, or relates directly or indirectly to, any
            interactions with, or act or omission of, other Service users or
            Third Party Sites & Ads.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Indemnification
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            You agree to indemnify and hold us (and our officers, employees, and
            agents) harmless, including costs and attorney's' fees, from any
            claim or demand made by any third party due to or arising out of (a)
            your use of the Services, (b) your User Content, (c) your violation
            of this Agreement; (d) your violation of applicable laws or
            regulations; (e) infringement of intellectual property rights; (d)
            any damages stemming from the relationship between a Buyer and
            Seller. We reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us and you agree to cooperate with our defense
            of these claims. You agree not to settle any matter without our
            prior written consent. We will use reasonable efforts to notify you
            of any such claim, action or proceeding upon becoming aware of it.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Disclaimers
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            THE SERVICES, INCLUDING THE SITE, ARE PROVIDED "AS-IS" AND "AS
            AVAILABLE" AND WE EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS
            OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR
            CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO
            WARRANTY THAT THE SERVICES: (a) WILL MEET YOUR REQUIREMENTS; (b)
            WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
            BASIS; (c) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER
            HARMFUL CODE, COMPLETE, LEGAL, OR SAFE OR (d) THAT THE SERVICES WILL
            BE TO YOUR SATISFACTION. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
            APPLY TO YOU.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Limitation on Liability
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
            PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
            SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS
            AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF
            WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO,
            AND USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND
            YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
            SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
          </p>
          <p>
            IN NO EVENT WILL ADSIGHT'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
            LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING,
            BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE AMOUNTS
            YOU'VE PAID ADSIGHT IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE
            OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE
            LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Term and Termination
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Subject to this Section, this Agreement will remain in full force
            and effect while you use the Services. We may (a) suspend your
            rights to use the Site and/or Services (including your Account) or
            (b) terminate this Agreement, at any time for any reason at our sole
            discretion, including for any use of the Services in violation of
            this Agreement. Upon termination of this Agreement, your Account and
            right to access and use the Services will terminate immediately. You
            understand that any termination of your Account involves deletion of
            your User Content from our live databases. We will not have any
            liability whatsoever to you for any termination of this Agreement,
            including for termination of your Account or deletion of your User
            Content. Upon termination of this Agreement, all of the provisions
            will terminate except those that by their nature should survive.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Counter Notice Procedures
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            If you receive a notification from Adsight that material made
            available by you on or through the Site has been the subject of a
            Notification of Claimed Infringement, then you will have the right
            to provide Adsight with what is called a "Counter Notification." To
            be effective, a Counter Notification must be in writing, provided to
            Company's Designated Agent through one of the methods identified
            above, and include substantially the following information:
          </p>
          <ul>
            <li>A physical or electronic signature of the subscriber.</li>
            <li>
              Identification of the material that has been removed or to which
              access has been disabled and the location at which the material
              appeared before it was removed or access to it was disabled.
            </li>
            <li>
              A statement under penalty of perjury that the subscriber has a
              good faith belief that the material was removed or disabled as a
              result of mistake or misidentification of the material to be
              removed or disabled.
            </li>
            <li>
              The subscriber's name, address, and telephone number, and a
              statement that the subscriber consents to the jurisdiction of
              Federal District Court for the judicial district in which the
              address is located, or if the subscriber's address is outside of
              the United States, for any judicial district in which Company may
              be found, and that the subscriber will accept service of process
              from the person who provided notification above or an agent of
              such person.
            </li>
          </ul>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Legal Disputes
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph has-text-weight-medium">
            PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL
            HAVE A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND ADSIGHT HAVE AGAINST
            EACH OTHER ARE RESOLVED.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph has-text-weight-medium">
            You and Adsight agree that any claim or dispute at law or equity
            that has arisen or may arise between us relating in any way to or
            arising out of this or previous versions of our Terms of Service
            Agreement, your use of or access to the Services, or any products or
            services sold or purchased through the Services, will be resolved in
            accordance with the provisions set forth in this Legal Disputes
            Section.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Choice of Law. This Agreement is made under and will be governed by
            and construed in accordance with the laws of province of British
            Columbia, without giving effect to any principles that provide for
            the application of the law of another jurisdiction.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Claim Limitations. You agree that any cause of action arising out of
            or related to the Services must commence within one (1) year after
            the cause of action accrues. Otherwise, such cause of action is
            permanently barred.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Agreement to Arbitrate: You and Adsight each agree that any and all
            disputes or claims that have arisen or may arise between you and
            Adsight relating in any way to or arising out of this or previous
            versions of the Terms of Service Agreement, your use of or access to
            Adsight 's Services, or any products or services sold, offered, or
            purchased through our Services will be resolved exclusively through
            final and binding arbitration, rather than in court. Alternatively,
            you may assert your claims in small claims court in British
            Columbia, Canada, if your claims qualify and so long as the matter
            remains in such court and advances only on an individual (non-class,
            non-representative) basis.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            The arbitration will be conducted by JAMS Arbitration ("JAMS") under
            its applicable rules and procedures, as modified by this Agreement
            to Arbitrate. The arbitration will be conducted before one
            commercial arbitrator with substantial experience in resolving
            commercial contract disputes. Your rights will be determined by a
            neutral arbitrator and not a judge or jury. You understand that
            arbitration procedures can be more limited than rules applicable in
            court. Arbitrator decisions are as enforceable as any court order
            and are subject to very limited review in court.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            You and we must abide by the following rules: (a) ANY CLAIMS BROUGHT
            BY YOU OR US MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY,
            AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE PROCEEDING; (b) THE ARBITRATOR MAY NOT CONSOLIDATE
            MORE THAN ONE PERSON'S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY
            FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD
            CLASS-WIDE RELIEF, (c) the arbitrator will honor claims of privilege
            and privacy recognized at law; (d) the arbitration will be
            confidential, and neither you nor we may disclose the existence,
            content or results of any arbitration, except as may be required by
            law or for purposes of enforcement of the arbitration award; (e) the
            arbitrator may award any individual relief or individual remedies
            that are permitted by applicable law; and (f) each side pays its own
            attorneys' fees and expenses unless there is a statutory provision
            that requires the prevailing party to be paid its fees and
            litigation expenses, and then in such instance, the fees and costs
            awarded will be determined by the applicable law.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            With the exception of subparts (a) and (b) in the paragraph above
            (prohibiting arbitration on a class or collective basis), if any
            part of this arbitration provision is deemed to be invalid,
            unenforceable or illegal, or otherwise conflicts with the Rules and
            Procedures, then the balance of this arbitration provision will
            remain in effect and will be construed in accordance with its terms
            as if the invalid, unenforceable, illegal or conflicting provision
            were not contained herein. If, however, either subpart (a) or (b) is
            found to be invalid, unenforceable or illegal, then the entirety of
            this arbitration provision will be null and void, and neither you
            nor we will be entitled to arbitration. If for any reason a claim
            proceeds in court rather than in arbitration, the dispute will be
            exclusively brought in province or federal court in British
            Columbia, Canada.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            General
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Changes to Agreement. This Agreement is subject to occasional
            revision, and if we make any substantial changes, we may notify you
            by sending you an e-mail to the last e-mail address you provided to
            us (if any) and/or by prominently posting notice of the changes on
            our Site. Any significant changes to this Agreement will be
            effective 30 days after posting such notice. You are responsible for
            providing us with your most current email address. In the event that
            the last e-mail address that you have provided us is not valid, or
            for any reason is not capable of delivering to you the notice
            described above, our dispatch of the e-mail containing such notice
            will nonetheless constitute effective notice of the changes
            described in the notice. Continued use of our Site or Services
            following notice of such changes will indicate your acknowledgement
            of such changes and agreement to be bound by the terms and
            conditions of such changes.
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Copyright/Trademark Information. Copyright 2019 Adsight, Inc.. All
            rights reserved. All trademarks, logos and service marks ("Marks")
            displayed on the Site are our property or the property of other
            third parties. You are not permitted to use these Marks without our
            prior written consent or the consent of such third party which may
            own the Marks.
          </p>
        </div>
        <div className="terms-of-use-modal__section">
          <p className="is-size-5 has-text-weight-medium terms-of-use-modal__section__title">
            Contact Information
          </p>
          <p className="is-size-6 terms-of-use-modal__section__paragraph">
            Adsight, Inc. Email: sales@adsight.ca Last Updated June 15, 2020.
          </p>
        </div>
        <div className="terms-of-use-modal__close-button">
          <Button text="Close" onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};
