import React from 'react';
import classNames from 'classnames';

import Input from '../../atoms/Input/input';

import './sign-up-form.scss';

const SignUpForm = ({
  values,
  touched,
  errors,
  isValid,
  handleBlur,
  handleChange,
  handleSubmit,
  loading,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Input
        id="firstName"
        name="firstName"
        labelName="First Name"
        type="text"
        required={true}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.firstName && errors.firstName ? (
        <p className="error-msg">{errors.firstName}</p>
      ) : null}

      <Input
        id="lastName"
        name="lastName"
        labelName="Last Name"
        type="text"
        required={true}
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.lastName && errors.lastName ? (
        <p className="error-msg">{errors.lastName}</p>
      ) : null}

      <Input
        id="phoneNumber"
        name="phoneNumber"
        labelName="Phone Number"
        type="tel"
        required={true}
        value={values.phoneNumber}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.phoneNumber && errors.phoneNumber ? (
        <p className="error-msg">{errors.phoneNumber}</p>
      ) : null}

      <Input
        id="companyName"
        name="companyName"
        labelName="Company Name"
        type="text"
        required={true}
        value={values.companyName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.companyName && errors.companyName ? (
        <p className="error-msg">{errors.companyName}</p>
      ) : null}

      <Input
        id="email"
        name="email"
        labelName="Email"
        type="text"
        required={true}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.email && errors.email ? (
        <p className="error-msg">{errors.email}</p>
      ) : null}

      <Input
        id="password"
        name="password"
        labelName="Password"
        type="password"
        required={true}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.password && errors.password ? (
        <p className="error-msg">{errors.password}</p>
      ) : null}

      <Input
        id="passwordConfirmation"
        name="passwordConfirmation"
        labelName="Confirm Password"
        type="password"
        required={true}
        value={values.passwordConfirmation}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {touched.passwordConfirmation && errors.passwordConfirmation ? (
        <p className="error-msg">{errors.passwordConfirmation}</p>
      ) : null}

      <button
        type="submit"
        disabled={!isValid}
        text="Sign Up"
        className={classNames('button is-primary submit-button', {
          'is-loading': loading,
        })}
      >
        Sign Up
      </button>
    </form>
  );
};

export default SignUpForm;
