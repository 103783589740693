import React from 'react';

import Layout from '../utils/layout';

import SignUp from '../components/scenes/SignUp/sign-up';

export default () => {
  return (
    <Layout>
      <SignUp />
    </Layout>
  );
};
